<template>
  <ion-page>
    <ion-header mode="ios">
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-back-button
            default-href="#"
            class="back-btn"
            text=""
            @click="$emit('closePage')"
            :icon="chevronBackOutline"
          >
          </ion-back-button>
        </ion-buttons>
        <ion-label class="head-title ml-2">{{
          invoiceDetail.credit_note?.length ? $t('invoice_cn.view_invoice_cn') : $t('view_invoice')
        }}</ion-label>
      </ion-toolbar>
    </ion-header>
    <ion-content>
      <div v-if="isOpenLoadingref" class="loading">
        <ion-spinner name="circles"></ion-spinner>
      </div>
      <div v-else class="bg-content">
        <div class="column bg-white mb-2">
          <ion-text color="primary" class="bold mb-2 text-uppercase">
            {{ $t('invoice_cn.invoice') }}
          </ion-text>
          <div class="column">
            <ion-row class="space-between row mb-2">
              <div class="column">
                <ion-text>{{ invoiceDetail.invoice_number }}</ion-text>
                <ion-text class="mt-2">
                  <ion-text color="primary" class="bold mr-2">
                    {{ invoiceDetail.display_grand_total }}
                  </ion-text>
                  <ion-label :class="getInvoiceStatus(invoiceDetail.outstanding_amount).class">
                    {{ getInvoiceStatus(invoiceDetail.outstanding_amount).text }}
                  </ion-label>
                </ion-text>
              </div>
              <div @click="setOpenInvoiceDetail(true)" class="view-btn px-3">
                {{ $t('invoice_cn.view') }}
              </div>
            </ion-row>
          </div>
        </div>
        <template v-if="invoiceDetail?.debitNotes?.length">
          <div class="column bg-white mb-2" v-for="debitNote in invoiceDetail.debitNotes" :key="debitNote.id">
            <ion-text color="primary" class="bold mb-2 text-uppercase">
              {{ $t('invoice_cn.invoice') }}
            </ion-text>
            <div class="column">
              <ion-row class="space-between row mb-2">
                <div class="column">
                  <ion-text>{{ debitNote.debit_note_number }}</ion-text>
                  <ion-text class="mt-2">
                    <ion-text color="primary" class="bold mr-2">
                      {{ currencySymbol + debitNote.outstanding_amount }}
                    </ion-text>
                    <ion-label :class="getInvoiceStatus(debitNote.outstanding_amount).class">
                      {{ getInvoiceStatus(debitNote.outstanding_amount).text }}
                    </ion-label>
                  </ion-text>
                </div>
                <div @click="setOpenDebitNoteDetail(true, debitNote)" class="view-btn px-3">
                  {{ $t('invoice_cn.view') }}
                </div>
              </ion-row>
            </div>
          </div>
        </template>
        <div class="column bg-white mb-2" v-if="invoiceDetail?.credit_note?.length">
          <ion-text color="primary" class="bold mb-2">
            {{ $t('invoice_cn.cn') }}
          </ion-text>
          <div class="column" v-for="credit_note in invoiceDetail.credit_note" :key="credit_note.id">
            <ion-row class="space-between row mb-2">
              <div class="column">
                <ion-text>{{ credit_note.credit_note_number }}</ion-text>
                <ion-text color="primary" class="bold mt-2">
                  {{ currencySymbol + credit_note.amount.toFixed(2) }}</ion-text
                >
              </div>
              <div @click="setOpenCreditNoteDetail(true, credit_note)" class="view-btn px-3">
                {{ $t('invoice_cn.view') }}
              </div>
            </ion-row>
          </div>
        </div>
      </div>
    </ion-content>
    <ion-footer v-if="!isOpenLoadingref">
      <ion-toolbar>
        <div class="column mx-2">
          <ion-button @click="downloadInvoiceCN" class="invoice-btn my-2" color="primary">
            <ion-icon slot="start" :icon="downloadOutline"></ion-icon>
            {{ $t('invoice_cn.download_all') }}
            {{ '(' + (invoiceDetail.credit_note?.length + invoiceDetail.debitNotes?.length + 1) + ')' }}
          </ion-button>
        </div>
      </ion-toolbar>
    </ion-footer>
    <ion-modal mode="ios" :is-open="isOpenCreditNote" @didDismiss="setOpenCreditNoteDetail(false, null)">
      <credit-note-detail
        :currencySymbol="currencySymbol"
        :detailOrderId="detailOrderId"
        :invoiceId="invoiceId"
        :invoiceDetail="invoiceDetail"
        :creditNoteDetail="creditNoteDetail"
        @close-page="setOpenCreditNoteDetail(false, null)"
      />
    </ion-modal>
    <ion-modal mode="ios" :is-open="isOpenInvoice" @didDismiss="setOpenInvoiceDetail(false)">
      <invoice-detail
        :currencySymbol="currencySymbol"
        :detailOrderId="detailOrderId"
        :invoiceId="invoiceId"
        @openPaymentHistory="onEnterPaymentHistory"
        @close-page="setOpenInvoiceDetail(false)"
      />
    </ion-modal>
    <ion-modal mode="ios" :is-open="isOpenDebitNote" @didDismiss="setOpenDebitNoteDetail(false)">
      <debit-note-detail
        :currencySymbol="currencySymbol"
        :detailOrderId="detailOrderId"
        :invoiceId="invoiceId"
        :debitNoteId="debitNoteDetail.id"
        @close-page="setOpenDebitNoteDetail(false)"
      />
    </ion-modal>
    <modal-payment-history
      :isOpenPaymentHistory="isOpenPaymentHistory"
      @closeModal="setOpenPaymentHistory(false)"
      :data="paymentHistories"
      :currencySymbol="currencySymbol"
    />
  </ion-page>
  <ion-loading
    :is-open="isDownloadInvoice"
    @didDismiss="setOpenDownload(false)"
    cssClass="custom-loading"
    message=""
    spinner="crescent"
  >
  </ion-loading>
</template>

<script>
import { apolloClient } from '@/main';
import { ModalPaymentHistory } from '@/modules/shared/components/invoices-payment';
import { CARD_INVOICES_LIST_COLOR } from '@/modules/shared/constants/';
import { toastError, toastSuccess } from '@/modules/shared/utils/';
import { downloadInvoiceCNPdf, getInvoiceDetail } from '@/services/shared/graphql';
import { Browser } from '@capacitor/browser';
import dayjs from 'dayjs';
import { checkmarkOutline, chevronBackOutline, closeOutline, downloadOutline } from 'ionicons/icons';
import { defineComponent, ref } from 'vue';
import CreditNoteDetail from './CreditNote';
import DebitNoteDetail from './DebitNote';
import InvoiceDetail from './Invoice';

export default defineComponent({
  inject: ['$storage'],
  components: {
    CreditNoteDetail,
    InvoiceDetail,
    ModalPaymentHistory,
    DebitNoteDetail
  },
  emits: ['closePage'],
  props: {
    detailOrderId: {
      type: Number,
      default: null
    },
    invoiceId: {
      type: Number,
      default: null
    },
    currencySymbol: {
      type: String,
      default: ''
    }
  },
  setup() {
    const { PAID_COLOR, UNPAID_COLOR, AWAITING_PAYMENT_COLOR } = CARD_INVOICES_LIST_COLOR;
    const invoiceDetail = ref({});
    const isOpenLoadingref = ref(true);
    const isDownloadInvoice = ref(false);
    const isOpenInvoice = ref(false);
    const isOpenCreditNote = ref(false);
    const isOpenDebitNote = ref(false);
    const creditNoteDetail = ref({});
    const debitNoteDetail = ref({});
    const setOpenLoading = (state) => (isOpenLoadingref.value = state);
    const setOpenDownload = (state) => (isDownloadInvoice.value = state);
    const isOpenPaymentHistory = ref(false);
    const setOpenPaymentHistory = (state) => (isOpenPaymentHistory.value = state);
    return {
      chevronBackOutline,
      downloadOutline,
      checkmarkOutline,
      closeOutline,
      invoiceDetail,
      isOpenInvoice,
      isOpenCreditNote,
      isOpenLoadingref,
      setOpenLoading,
      isDownloadInvoice,
      setOpenDownload,
      creditNoteDetail,
      batchDocs: ref(null),
      isOpenPaymentHistory,
      setOpenPaymentHistory,
      paymentHistories: ref({}),
      isOpenDebitNote,
      debitNoteDetail,
      PAID_COLOR,
      UNPAID_COLOR,
      AWAITING_PAYMENT_COLOR
    };
  },
  async mounted() {
    this.checkInvoiceAndCreditNotes();
  },
  methods: {
    async checkInvoiceAndCreditNotes() {
      const { tenant } = await this.$storage.getUser();
      const { data } = await apolloClient.query({
        query: getInvoiceDetail,
        variables: {
          invoiceId: this.invoiceId,
          tenantId: tenant?.id
        }
      });
      this.invoiceDetail = data.getInvoiceDetail;

      this.setOpenLoading(false);
    },
    async downloadInvoiceCN() {
      this.setOpenDownload(true);
      try {
        const { data } = await apolloClient.mutate({
          mutation: downloadInvoiceCNPdf,
          variables: {
            orderId: this.detailOrderId,
            creditNoteIds: this.invoiceDetail.credit_note?.map((cn) => cn.id) || []
          }
        });
        this.batchDocs = data.downloadInvoiceCNPdf;
        if (!this.batchDocs) {
          toastError(this.$t('invoice_cn.download_file_fail'));
          return;
        }
        await Browser.open({ url: data.downloadInvoiceCNPdf });
        toastSuccess(this.$t('invoice_cn.download_file'));
      } catch (e) {
        toastError(this.$t('invoice_cn.download_file_fail'));
        console.log(e);
      } finally {
        this.setOpenDownload(false);
      }
    },
    onEnterPaymentHistory(paymentHistories) {
      this.paymentHistories = paymentHistories;
      this.setOpenPaymentHistory(true);
    },
    setOpenInvoiceDetail(val) {
      this.isOpenInvoice = val;
    },
    setOpenCreditNoteDetail(val, cnId) {
      cnId && (this.creditNoteDetail = cnId);
      this.isOpenCreditNote = val;
    },
    setOpenDebitNoteDetail(val, debitNote) {
      debitNote && (this.debitNoteDetail = debitNote);
      this.isOpenDebitNote = val;
    },
    getInvoiceStatus(outstandingAmount) {
      if (outstandingAmount === 0) {
        return {
          text: 'Paid',
          class: this.PAID_COLOR
        };
      } else {
        const dueDate = dayjs(this.invoiceDetail.customer_due_date);
        if (dueDate.isBefore(dayjs().endOf('day'))) {
          return {
            text: 'Overdue',
            class: this.UNPAID_COLOR
          };
        } else {
          return {
            text: 'Outstanding',
            class: this.AWAITING_PAYMENT_COLOR
          };
        }
      }
    }
  }
});
</script>
<style src="./style.scss" lang="scss" scoped></style>
